import React from "react"
import SEO from "../components/seo"
import Chapter2 from "src/views/Chapter2"
import OGImage from "../images/seo/historia_ma_glos.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Najważniejsze wydarzenia XX wieku zapisane w dźwięku"
        description="Wybuch II wojny światowej, lądowanie człowieka na Księżycu, upadek komunizmu w Polsce – Polskie Radio było zawsze tam, gdzie działa się historia"
        image={OGImage}
      />
      <Chapter2 />
    </>
  )
}

export default IndexPage
