import React, { useRef } from "react"
import { useArticles } from "src/hooks"
import { Iterator } from "src/util"
import TitleAndParagraph from "src/components/TitleAndParagraph"
import ArticleRows from "src/components/ArticleRows"
import NextChapterLink from "src/components/NextChapterLink"
import BodyWrap from "src/components/BodyWrap"
import { AnimatePresence, motion } from "framer-motion"
import H1 from "src/components/H1"

const Chapter1 = () => {
  const articles = useArticles({ articleCategory: 8964 })
  const iterator = useRef()

  return (
    <AnimatePresence exitBeforeEnter>
      {articles.list?.length > 0 &&
        (iterator.current = new Iterator({ array: articles.list })) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BodyWrap>
              <H1 />
              <TitleAndParagraph article={iterator.current.current()} />
              <ArticleRows
                articles={iterator.current.next(4)}
                variant="short"
              />

              <TitleAndParagraph article={iterator.current.next()} />
              <ArticleRows
                articles={iterator.current.next(4)}
                variant="short"
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <ArticleRows
                articles={iterator.current.next(19)}
                variant="short"
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <ArticleRows
                articles={iterator.current.next(9)}
                variant="short"
              />
            </BodyWrap>
            <NextChapterLink title="Teatr wyobraźni" href="teatr-wyobrazni" />
          </motion.div>
        )}
    </AnimatePresence>
  )
}

export default Chapter1
